<template>
  <router-link to="/">
    <header>
      <b-navbar toggleable="lg" fixed="top" class="d-flex flex-column p-0 dark" style="padding-left: 110px;">
        <div class="col-sm-12 nav-principal pl-0">
          <div class="d-lg-flex">
            <div class=" d-flex flex-nowrap p-0">
              <div class="col-3 p-0">
                <b-navbar-brand to="/" title="Página Principal" class="p-0">
                  <img class="site-logo" alt="Logo Faculdade Alfamérica" src="../assets/img/icons/logo.svg">
                </b-navbar-brand>
              </div>
              <div class="col navbar-expand-lg navbar-toggler d-lg-none d-flex justify-content-end align-items-center">
                <b-navbar-toggle target="nav-collapse" class="">
                  <template v-slot:default="{ expanded }">
                    <b-icon v-if="expanded" color="#FFF" icon="chevron-bar-up"></b-icon>
                    <b-icon v-else color="#FFF" icon="chevron-bar-down"></b-icon>
                  </template>
                </b-navbar-toggle>
              </div>
            </div>
            <b-collapse id="nav-collapse" is-nav class="pt-2 pb-2">
              <b-navbar-nav class="m-auto pl-2" right>
                <b-nav-item @click="cpa">CPA</b-nav-item>
                <b-nav-item v-if="$route.path === '/'" @click.prevent="scrollToSection('#quemsomos')">QUEM
                  SOMOS</b-nav-item>
                <b-nav-item @click="revista">REVISTA</b-nav-item>
                <b-nav-item v-if="$route.path === '/'" @click.prevent="scrollToSection('#contato')">CONTATO</b-nav-item>
                <b-nav-item to="/acessibilidade">ACESSIBILIDADE</b-nav-item>
                <b-nav-item to="/biblioteca">BIBLIOTECA</b-nav-item>
                <b-nav-item to="/brinquedoteca">BRINQUEDOTECA</b-nav-item>
              </b-navbar-nav>
            </b-collapse>
          </div>
        </div>
      </b-navbar>
    </header>
  </router-link>
</template>

<script>
export default {
  name: 'NavBar',
  data() {
    return {
      isFixed: false,
      url: "https://faculdadealfamerica.edu.br/cpa/cpa.html",
    };
  },
  mounted() {
    window.addEventListener("scroll", this.handleScroll);
  },
  destroyed() {
    window.removeEventListener("scroll", this.handleScroll);
  },
  methods: {
    handleScroll() {
      const menuTop = this.$el.offsetTop;
      const scrollTop = window.pageYOffset;
      if (scrollTop > menuTop) {
        this.isFixed = true;
      } else {
        this.isFixed = false;
      }
    },
    scrollToSection(sectionId) {
      const section = document.querySelector(sectionId);
      const navHeight = document.querySelector('nav').offsetHeight;
      const sectionTop = section.offsetTop - navHeight;
      window.scrollTo({
        top: sectionTop,
        behavior: 'smooth'
      });
    },
    revista() {
      // Replace the URL with the desired destination URL
      const url = "https://faculdadealfamerica.edu.br/revista";

      // Redirect to the destination URL
      window.location.href = url;
    },
    cpa() {
      // Replace the URL with the desired destination URL
      const url = "https://faculdadealfamerica.edu.br/cpa/cpa.html";

      // Redirect to the destination URL
      window.location.href = url;
    },
    acessibilidade() {
      // Replace the URL with the desired destination URL
      const url = "https://faculdadealfamerica.edu.br/acessibilidade";

      // Redirect to the destination URL
      window.location.href = url;
    },

  }
}
</script>

<style>
.nav-principal {
  background: #212B55 !important;
}

.nav-principal .navbar-nav a {
  color: #FFFFFF !important;
}

.nav-principal .navbar-nav a:hover,
.nav-principal .navbar-nav .active a {
  border-bottom: 3px solid #B7182E !important;
  transition: border-bottom-color 0.5s ease-out !important;
}

button.navbar-toggler.collapsed,
button.navbar-toggler.not-collapsed {
  background-color: #B7182E !important;
  height: 100%;
  max-height: 44px;
}

div.navbar-expand-lg.navbar-toggler {
  background-color: unset !important;
}

.navbar-light .navbar-toggler {
  color: unset;
  border: unset;
}

header.fixed-menu {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  z-index: 999;
}


header {
  background-color: #212B55;
  display: flex !important;
  align-items: center;
  justify-content: center;
  /* position: static; */
  box-shadow: 0px 2px 7px 5px rgba(0, 0, 0, 0.3);
}

.container-menu li a {
  font-family: 'Effra Bold', sans-serif;
  color: #FFFFFF !important;
  cursor: pointer;
  /* text-decoration: none; */
  text-decoration-color: #FFFFFF;
}


li:not(:nth-last-child(1)) {
  margin-right: 40px;
}

@media (max-width: 576px) {
  .site-logo {
    max-width: 48px;
  }

  .container-menu {
    font-size: 12px;
  }

  .container-menu li:not(:nth-last-child(1)) {
    margin-right: 16px;
  }
}
</style>