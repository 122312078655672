import Vue from 'vue'
import VueRouter from 'vue-router'

import Home from '../src/views/Home.vue'

Vue.use(VueRouter)

const routes = [
    {
      path: '/',
      name: 'home',
      component: Home
    },
    {
      path: '/contato',
      name: 'contato',
      component: () =>
      import(
        /* webpackChunkName: "Contato" */ '../src/views/FormContact.vue'
      )
    },
    {
      path: '/validar',
      name: 'validar',
      component: () =>
      import(
        /* webpackChunkName: "Contato" */ '../src/views/ValidacaoDiploma.vue'
      )
    },
    {
      path: "/documentosInstitucionais",
      name: "Documentos Institucionais",
      meta: {
        title: "Documentos Institucionais"
      },
      component: () =>
        import(/* webpackChunkName: "Listagem documento" */ "../src/views/ListagemDocumento.vue")
    },
    {
      path: "/acessibilidade",
      name: "acessibilidade",
      // meta: {
      //   title: "Acessibilidade"
      // },
      component: () =>
        import("../src/views/AcessibilidadeList.vue")
    },
    {
      path: "/brinquedoteca",
      name: "brinquedoteca",
      meta: {
        title: "Brinquedoteca"
      },
      component: () =>
        import("../src/views/BrinquedotecaList.vue")
    },
    {
      path: "/requerimentos",
      name: "requerimentos",
      meta: {
        title: "Requerimentos"
      },
      component: () =>
        import("../src/views/Requerimentos/BuscarRequerimento.vue")
    },
    {
      path: "/novo-requerimento",
      name: "requerimento-outros",
      meta: {
        title: "Novo Requerimento"
      },
      component: () =>
        import("../src/views/Requerimentos/NovoRequerimento.vue")
    },
    {
      path: "/historico-requerimento",
      name: "historico",
      meta: {
        title: "Novo Requerimento"
      },
      component: () =>
        import("../src/views/Requerimentos/HistoricoRequerimento.vue")
    },
    {
      path: "/acesso",
      name: "validacao-Acesso",
      meta: {
        title: "Validação Acesso"
      },
      component: () =>
        import(/* webpackChunkName: "acesso" */ "../src/views/ValidacaoAcesso.vue")
    },
    {
      path: "/baixarArquivos",
      name: "Arquivos para baixar",
      meta: {
        title: "Arquivos para baixar"
      },
      component: () =>
        import(/* webpackChunkName: "Listagem documento" */ "../src/views/BaixarArquivos.vue")
    },
    {
      path: "/documento",
      name: "documento",
      meta: {
        title: "Documentos"
      },
      component: () =>
        import(/* webpackChunkName: "Documentos" */ "../src/views/DocumentoList.vue")
    },
    {
    path: "/secretaria/login",
    name: "secretaria-login",
    meta: {
      title: "Login Secretaria"
    },
    component: () =>
      import(/* webpackChunkName: "Login Secretaria" */ "../src/views/Secretaria/SecretariaLogin.vue")
  },
  {
    path: "/secretaria",
    name: "secretaria",
    meta: {
      title: "Secretaria Painel"
    },
    component: () =>
      import(/* webpackChunkName: "Secretaria" */"../src/views/Secretaria/SecretariaPainel.vue")
  },
  {
    path: "/declaracao",
    name: "declaracao",
    meta: {
      title: "Declaracao"
    },
    component: () =>
      import(/* webpackChunkName: "Declaracao" */"../src/views/DeclaracaoList.vue")
  },
  {
    path: "/biblioteca",
    name: "biblioteca-digital",
    meta: {
      title: "Biblioteca Digital"
    },
    component: () =>
      import("../src/views/MinhaBiblioteca.vue")
  },
  {
    path: "/comunicados",
    name: "comunicados",
    meta: {
      title: "Comunicados"
    },
    component: () =>
      import("../src/views/Comunicados.vue")
  },
  {
    path: "/revista",
    name: "revista",
    meta: {
      title: "Revista"
    },
    component: () =>
      import("../src/views/RevistaAlfa.vue")
  }
  ];


  const router = new VueRouter({
    mode: "history",
    base: process.env.BASE_URL,
    routes,
    scrollBehavior() {
      return { x: 0, y: 0 };
      /*  if (pageNotScroll.includes(to.name)) {
        return savedPosition;
      } else {
        return { x: 0, y: 0 };
      }*/
    }
  });

  router.beforeEach((to, from, next) => {
    document.title =
      process.env.VUE_APP_HOST == "DEV" ? "[DEV] ALFAMÉRICA - A FACULDADE QUE VOCÊ PROCURA" : "ALFAMÉRICA - A FACULDADE QUE VOCÊ PROCURA";
    if (to.meta.title) document.title = to.meta.title + " - ALFAMÉRICA - A FACULDADE QUE VOCÊ PROCURA";
    next();
  });
  
export default router
